
import BookmarkProductModal from '~/components/modals/BookmarkProductModal'

export default {
  name: 'BookmarkProductModalButton',

  components: {
    BookmarkProductModal,
  },

  props: {
    btnClasses: {
      type: String,
      default: null,
    },

    data: {
      type: Object,
      required: true,
    },

    isWhite: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      open: false,
    }
  },

  methods: {
    async setOpen() {
      await this.$store.dispatch('shopping-lists/all')
      this.open = true
    },
  },
}
