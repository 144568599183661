import { saveAs } from 'file-saver'

export default {
  methods: {
    async downloadAsset(asset) {
      const downloadingToast = this.$toasted.info(
        'Die Datei wird heruntergeladen...',
        {
          duration: 0,
        }
      )

      try {
        const { data } = await this.$axios.get(asset.permalink, {
          responseType: 'arraybuffer',
        })

        const blob = new Blob([data], { type: asset.mime_type })

        saveAs(blob, asset.basename)
      } catch (error) {
        console.log({ error })
        if (error.response?.status === 401) {
          this.$toasted.error(this.$t('download-not-allowed'))
        } else {
          this.$toasted.error(this.$t('download-failed'))
        }
      } finally {
        downloadingToast.goAway(0)
      }
    },
  },
}
