
import DownloadAsset from '~/mixins/DownloadAsset'
import ProductFunctions from '~/mixins/ProductFunctions'
import BookmarkProductModalButton from '~/components/modals/buttons/BookmarkProductModalButton'

export default {
  components: {
    BookmarkProductModalButton,
  },

  mixins: [DownloadAsset, ProductFunctions],

  props: {
    product: {
      type: Object,
      required: true,
    },
  },

  computed: {
    hasAuthors() {
      return this.product.authors?.length
    },

    formats() {
      let additionalFormats = this.product.purchasableFormats?.map(
        (relation) => relation.product
      )

      if (!additionalFormats) {
        additionalFormats = []
      }

      return [this.product, ...additionalFormats]
    },
  },

  methods: {
    visibilityChanged(isVisible, entry) {
      const payload = {
        isVisible,
        entry,
      }
      this.$emit('visibility-changed', payload)
    },

    scrollTo(scrollToSlug) {
      const search = '[scroll-to-slug="' + scrollToSlug + '"]'
      const component = document.querySelector(search)
      if (!component) {
        return
      }
      component.scrollIntoView({ block: 'center', behavior: 'smooth' })
    },
  },
}
