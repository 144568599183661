
export default {
  components: {},

  props: {
    isNegative: {
      type: Boolean,
      default: false,
    },

    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    clickableBreadcrumbs() {
      return this.breadcrumbs.filter((breadcrumb) => breadcrumb.is_link)
    },

    notHomepage() {
      return this.$route.path !== '/'
    },
  },

  methods: {},
}
