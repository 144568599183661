import { render, staticRenderFns } from "./ProductHero.vue?vue&type=template&id=3cccf1bf&scoped=true&"
import script from "./ProductHero.vue?vue&type=script&lang=js&"
export * from "./ProductHero.vue?vue&type=script&lang=js&"
import style0 from "./ProductHero.vue?vue&type=style&index=0&id=3cccf1bf&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cccf1bf",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BreadCrumbs: require('/vercel/path0/components/general/BreadCrumbs.vue').default,FixedProductNav: require('/vercel/path0/components/heros/FixedProductNav.vue').default,AppImage: require('/vercel/path0/components/general/AppImage.vue').default,ProductPrice: require('/vercel/path0/components/product/ProductPrice.vue').default,Scrolly: require('/vercel/path0/components/icons/Scrolly.vue').default,Container: require('/vercel/path0/components/general/Container.vue').default,SelectableBgColor: require('/vercel/path0/components/general/SelectableBgColor.vue').default})
