
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      hideFixedNav: false,
      fixedNavOpacity: true,
      isNegative: true,
    }
  },

  computed: {
    fixedNavData() {
      return this.$store.getters['product-fixed-nav/fixedNavData']
    },
  },

  watch: {
    // determine the bg_color of the component in viewport and adjust text and item color corresponding
    fixedNavData() {
      // retrun if nothing is visible (shout not be the case)
      if (!this.fixedNavData.isVisible) {
        return
      }
      // if component in viewport is the productHero
      if (this.fixedNavData.productNavigationSlug === 'hero') {
        // is no bg_color is set for the hero set text black -> due to the bg_color will be white
        // if hero bg_color is white or light gray set text color to black
        if (
          !this.product.hero_background_color ||
          this.product.hero_background_color.key === null ||
          this.product.hero_background_color.key === 'white' ||
          this.product.hero_background_color.key === 'gray-light'
        ) {
          this.isNegative = false // black
          return
        } else {
          this.isNegative = true // white
          return
        }
      }

      // if a component is in viewport loop through the components to determine the bg_color set in the data
      for (let i = 0; i < this.product.components.length; i++) {
        // check for the product_navigation_slug
        if (
          this.product.components[i].product_navigation_slug !==
          this.fixedNavData.productNavigationSlug
        ) {
          continue
        }
        // if no bg_color ist specified -> background will be white
        if (
          !this.product.components[i].background_color ||
          this.product.components[i].background_color.key === null ||
          this.product.components[i].background_color.key === 'white' ||
          this.product.components[i].background_color.key === 'gray-light'
        ) {
          this.isNegative = false // black
          continue
        } else {
          this.isNegative = true // white
        }
      }
    },
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('scroll', this.handleScroll2)

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })

    this.onResize()
  },

  beforeDestory() {
    window.removeEventListener('scroll', this.handleScroll)

    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    active(slug) {
      if (
        this.fixedNavData.productNavigationSlug === slug &&
        slug !== null &&
        this.fixedNavData.isVisible
      ) {
        return true
      } else {
        return false
      }
    },

    scrollToComponent(slug) {
      const search = '[product-navigation-slug="' + slug + '"]'
      const component = document.querySelector(search)
      if (!component) {
        return
      }
      window.scrollTo({
        top: component.offsetTop,
        left: 0,
        behavior: 'smooth',
      })
    },

    isElementVisible(el) {
      if (!el) {
        return true
      }
      const rect = el.getBoundingClientRect()
      const vWidth = window.innerWidth || document.documentElement.clientWidth
      const vHeight =
        window.innerHeight || document.documentElement.clientHeight
      const efp = function (x, y) {
        return document.elementFromPoint(x, y)
      }

      // Return false if it's not in the viewport
      if (
        rect.right < 0 ||
        rect.bottom < 0 ||
        rect.left > vWidth ||
        rect.top > vHeight
      ) {
        return false
      }

      // Return true if any of its four corners are visible
      return (
        el.contains(efp(rect.left, rect.top)) ||
        el.contains(efp(rect.right, rect.top)) ||
        el.contains(efp(rect.right, rect.bottom)) ||
        el.contains(efp(rect.left, rect.bottom))
      )
    },

    handleScroll(event) {
      const x = document.getElementById('pageFooter')
      const y = document.getElementById('productHero')

      this.hideFixedNav = this.isElementVisible(x)
      this.fixedNavOpacity = this.isElementVisible(y)
    },

    onResize() {
      let windowHeight = 0.5

      if (window.innerHeight > 1024) {
        windowHeight = 1
      }

      this.$store.commit('product-fixed-nav/setWindowHeight', windowHeight)
    },
  },
}
